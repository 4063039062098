// select the target node
var target = document.getElementById("mce-success-response");

// create an observer instance
var observer = new MutationObserver(function (mutations) {
	mutations.forEach(function (mutation) {
		if (target.innerHTML === "Tak for din tilmelding") {
			target.innerHTML =
				"Tusind tak for din interesse! Vi vil i løbet af kort tid tage kontakt til dig.";
		}
		var elmnt = document.getElementById("mc-embedded-subscribe-form");
		elmnt.scrollIntoView();
	});
});

// configuration of the observer:
var config = { attributes: true, childList: true, characterData: true };

// pass in the target node, as well as the observer options
observer.observe(target, config);
